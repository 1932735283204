import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './application'
import reducer from './reducer'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import { BackendMiddleware } from './backend/middleware'
import { BinaryCacheMiddleware } from './binarystorage'

const middlewares = [
	BinaryCacheMiddleware,
	BackendMiddleware,
]

ReactDOM.render(
	<Provider store={createStore(reducer, applyMiddleware(...middlewares))}>
		<App />
	</Provider>,
	document.getElementById('root')
)
