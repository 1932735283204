import React, { Component } from 'react'
import { State as GlobalState } from '../reducer'
import { connect } from 'react-redux'
import { DatumObject, ZeroKey } from 'datum-api'
import { Action, ActionObject } from '../actions'
import IconClear from '@material-ui/icons/Clear'
import IconPlaylistAdd from '@material-ui/icons/PlaylistAdd'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { randomBytes } from 'crypto'
import Paper from '@material-ui/core/Paper'

export function uuidv4():string {
  return [1e7,1e3,4e3,8e3,1e11].join('-').replace(/[018]/g, (cs:string)=>{
		const c:number = +cs
		return ((c ^ randomBytes(1)[0]) & 15 >> c / 4).toString(16)
	})
}

interface OwnProps {
	onChange:(text:string)=>void
	onClear?:()=>void
}
interface StateProps {}
interface DispatchProps {
	onSubmit:(datum:DatumObject)=>void

}
interface Props extends OwnProps, StateProps, DispatchProps {}

const mapStateToProps = (state:GlobalState):StateProps=>{
	return {}
}
const mapDispatchToProps = (dispatch:React.Dispatch<ActionObject>, props:OwnProps):DispatchProps=>{
	return {
		onSubmit: (datum:DatumObject)=>dispatch(Action.createCard({...datum, key: uuidv4()})),
	}
}

interface State {
	datum:DatumObject
}

const emptyObject:DatumObject = {
	key: ZeroKey,
	attributes: {},
	text: '',
	attachments: [],
	created: 0,
	updated: 0,
}

class FirstCard extends Component<Props, State> {

	static defaultState:State = {
		datum: emptyObject,
	}

	static defaultProps = {
	}

	state = FirstCard.defaultState

	reset = (clear:boolean)=>{
		this.setState(FirstCard.defaultState)
		if (clear) {
			this.props.onClear && this.props.onClear()
		}
	}

	onTextChange = (event:React.ChangeEvent<HTMLTextAreaElement>)=>{
		const text = event.target.value
		this.setState({datum:{
			...this.state.datum,
			text: text,
		}})
		this.props.onChange(text)
	}

	onSubmit = ()=>{
		const empty = (this.state.datum.text === '') || (this.state.datum.attachments === [])
		if (!empty) {
			this.props.onSubmit(this.state.datum)
			this.reset(false)
		}
	}

	render() {
		return (
			<Paper className='element'>
				<TextField
					variant='outlined'
					autoFocus
					value={this.state.datum.text}
					multiline
					className='first-card-text-field'
					placeholder='Search'
					onChange={this.onTextChange}
					InputProps={{
						endAdornment:(
						<InputAdornment position='end'>
							<IconButton onClick={()=>this.reset(true)}>
								<IconClear />
							</IconButton>
						</InputAdornment>)
					}}
				/>
				<div className='card-control'>
					<div className='card-control-row'>
						<IconButton onClick={this.onSubmit}>
							<IconPlaylistAdd />
						</IconButton>
					</div>
				</div>
			</Paper>
		)
	}
}

export default connect<StateProps, DispatchProps, OwnProps, GlobalState>(mapStateToProps, mapDispatchToProps)(FirstCard)
