import React, { Component } from 'react'
import { State as GlobalState } from '../reducer'
import { connect } from 'react-redux'
import { DatumObject } from 'datum-api'
import Paper from '@material-ui/core/Paper'
import Modal from '@material-ui/core/Modal'
import InputBase from '@material-ui/core/InputBase'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { Action, ActionObject } from '../actions'
import FetchedCard from './fetchedcard'

function Interval(fn:()=>void, ms:number):(()=>void) {
	const id = setInterval(fn, ms)
	return ()=>{
		clearInterval(id)
	}
}

interface OwnProps {
	datum:DatumObject
}
interface StateProps {}
interface DispatchProps {
	save: (datum:DatumObject)=>void
}
interface Props extends OwnProps, StateProps, DispatchProps {}
const mapStateToProps = (state:GlobalState, props:OwnProps):StateProps=>{
	return {}
}
const mapDispatchToProps = (dispatch:React.Dispatch<ActionObject>, props:OwnProps):DispatchProps=>{
	return {
		save: (datum:DatumObject)=>dispatch(Action.updateCards([datum])),
	}
}

interface State {
	datum:DatumObject
}

class FocusedCardImpl extends Component<Props, State> {
	state = {
		datum: this.props.datum,
	}

	unregister:(()=>void)[] = []

	componentDidMount() {
		this.unregister.push(Interval(this.save, 30000))
	}

	componentWillUnmount() {
		this.unregister.forEach(f=>f())
		this.unregister = []
	}

	componentDidUpdate(prevProps:Readonly<Props>) {
		if (prevProps.datum !== this.props.datum) {
			this.componentWillUnmount()
			this.componentDidMount()
		}
	}

	onTextChange = (event:React.ChangeEvent<HTMLTextAreaElement>)=>{
		const text = event.target.value
		const datum = this.state.datum
		this.setState({datum: {...datum, text: text}})
	}

	save = ()=>{
		this.props.save(this.state.datum)
	}

	render() {
		return (
			<Paper className='focused-element'>
				<InputBase
					multiline={true}
					autoFocus
					fullWidth
					onBlur={this.save}
					value={this.state.datum.text}
					onChange={this.onTextChange}
					classes={{input: 'focused-text-height'}}
				/>
			</Paper>
		)
	}
}

const FocusedCard = connect<StateProps, DispatchProps, OwnProps, GlobalState>(mapStateToProps, mapDispatchToProps)(FocusedCardImpl)

function FetchedFocusedCard(props:any) {
	return (
		<Modal open={true} className='focused-root'>
			<ClickAwayListener onClickAway={props.close}>
				<div>
					<FetchedCard
						datumId={props.datumId}
						Loaded={(datum:DatumObject)=><FocusedCard datum={datum}/>}
						Loading={(datumId:string)=>
							<div className='container'>
								<Paper className='element'>Loading...</Paper>
							</div>}
						Missing={(datumId:string)=>
							<div className='container'>
								<Paper className='element'>Requested card does not exist.</Paper>
							</div>}
					/>
				</div>
			</ClickAwayListener>
		</Modal>
	)
}

export default connect<{datumId:string}>(null, (dispatch)=>{return {
	close: ()=>dispatch(Action.setFocusedCard('')),
}})(FetchedFocusedCard)
