import { DatumObject, Client } from 'datum-api'
import { Action, ActionType, ServerAction, ActionObject } from './actions'
import { Store, Dispatch } from 'redux'

export const BinaryCacheMiddleware = (store:Store)=>(next:Dispatch<ActionObject>)=>(action:ActionObject)=>{
	// if (Action.is(action, ActionType.loadBinary)) {
	// 	if (not cache contains) {
	// 		store.dispatch(ServerAction.enqueueCommand(GetBinary(action.binaryDataId)))
	// 	}
	// }
	return next(action)
}
