import React from 'react'
import { DatumObject } from 'datum-api'
import Card from './card'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import FetchedCard from './fetchedcard'

export default function (props:{datumId:string}) {
	return (
		<FetchedCard
			datumId={props.datumId}
			Loaded={(datum:DatumObject)=><Card datum={datum}/>}
			Loading={(datumId:string)=>
				<div className='container'>
					<Paper className='element'>
						<TextField
							variant='outlined'
							disabled
							multiline
							className='card-text-field card-text'
							placeholder='Loading...'
						/>
					</Paper>
				</div>}
			Missing={(datumId:string)=>
				<div className='container'>
					<Paper className='element'>Requested card does not exist.</Paper>
				</div>}
		/>
	)
}
