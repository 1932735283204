import { Server } from 'datum-api'
import { Dispatch } from 'redux'
import { Action, ServerAction } from '../actions'

export interface ApiHandler {
	(clientEvent:Server.EventObject):void
}

export interface UnboundApiHandler {
	(dispatch:Dispatch):[Server.EventType, ApiHandler]
}

export const UnboundHandlers:UnboundApiHandler[] = [
	(dispatch:Dispatch) => [Server.EventType.message, messageHandler(dispatch)],
	(dispatch:Dispatch) => [Server.EventType.data, dataHandler(dispatch)],
	(dispatch:Dispatch) => [Server.EventType.pinned, pinnedHandler(dispatch)],
	(dispatch:Dispatch) => [Server.EventType.searchResult, searchResultHandler(dispatch)],
	(dispatch:Dispatch) => [Server.EventType.download, downloadHandler(dispatch)],
	(dispatch:Dispatch) => [Server.EventType.downloadFragment, downloadFragmentHandler(dispatch)],
	(dispatch:Dispatch) => [Server.EventType.downloadAborted, downloadAbortedHandler(dispatch)],
	(dispatch:Dispatch) => [Server.EventType.uploadFailed, uploadFailedHandler(dispatch)],
	(dispatch:Dispatch) => [Server.EventType.dataNotFound, dataNotFoundHandler(dispatch)],
	(dispatch:Dispatch) => [Server.EventType.binaryDataNotFound, binaryDataNotFoundHandler(dispatch)],
]

const messageHandler = (dispatch:Dispatch):ApiHandler=>async (clientEvent:Server.EventObject)=>{
	throw new Error('Not implemented')
}

const dataHandler = (dispatch:Dispatch):ApiHandler=>async (clientEvent:Server.EventObject)=>{
	if (Server.is(clientEvent, Server.EventType.data)) {
		dispatch(Action.updateCards(clientEvent.data))
	}
}

const pinnedHandler = (dispatch:Dispatch):ApiHandler=>async (clientEvent:Server.EventObject)=>{
	if (Server.is(clientEvent, Server.EventType.pinned)) {
		dispatch(ServerAction.setPinnedCards(clientEvent.pinnedData))
	}
}

const searchResultHandler = (dispatch:Dispatch):ApiHandler=>async (clientEvent:Server.EventObject)=>{
	if (Server.is(clientEvent, Server.EventType.searchResult)) {
		dispatch(Action.setDisplayedCards(clientEvent.keys))
	}
}

const downloadHandler = (dispatch:Dispatch):ApiHandler=>async (clientEvent:Server.EventObject)=>{
	throw new Error('Not implemented')
}

const downloadFragmentHandler = (dispatch:Dispatch):ApiHandler=>async (clientEvent:Server.EventObject)=>{
	throw new Error('Not implemented')
}

const downloadAbortedHandler = (dispatch:Dispatch):ApiHandler=>async (clientEvent:Server.EventObject)=>{
	throw new Error('Not implemented')
}

const uploadFailedHandler = (dispatch:Dispatch):ApiHandler=>async (clientEvent:Server.EventObject)=>{
	throw new Error('Not implemented')
}

const dataNotFoundHandler = (dispatch:Dispatch):ApiHandler=>async (clientEvent:Server.EventObject)=>{
	if (Server.is(clientEvent, Server.EventType.dataNotFound)) {
		dispatch(Action.dataNotFound(clientEvent.keys))
	}
}

const binaryDataNotFoundHandler = (dispatch:Dispatch):ApiHandler=>async (clientEvent:Server.EventObject)=>{
	throw new Error('Not implemented')
}