import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import StartServer from './server'
import { State as GlobalState, ConnectionState } from '../reducer'
import { ServerAction, ActionObject } from '../actions'
import { ServerObject } from './server'

interface Props {
	connectionState:ConnectionState
	dispatch:Dispatch<ActionObject>
	websocketUrl:string
	serverCommandQueue:any[]
}
interface State {
	serverObject?:ServerObject
}

const mapStateToProps = (state:GlobalState):Props=>{
	return {
		connectionState: state.connectionState,
		websocketUrl: state.websocketUrl,
		serverCommandQueue: state.serverCommandQueue,
	} as Props
}
const mapDispatchToProps = (dispatch:Dispatch<ActionObject>):Props=>{
	return {
		dispatch
	} as Props
}

const DEV = process.env.NODE_ENV === 'development'

class ServerComponent extends Component<Props, State> {
	
	state:State = {}

	onConnectionStateChanged = async (connectionState:ConnectionState)=>{
		const dispatch = this.props.dispatch
		if (connectionState === ConnectionState.pending) {
			const response = await fetch('/api/connect', {
				method: 'POST',
				credentials: DEV ? 'include' : 'same-origin',
			})
			if (response.status === 200) {
				const protocol = await response.text()
				dispatch(ServerAction.connectionStateConnecting(protocol))
			}
			else if (response.status === 401) {
				dispatch(ServerAction.connectionStateLoggedOut())
			}
			else {
				// TODO: figure out what to do here
				dispatch(ServerAction.connectionStateLoggedOut())
			}
		}
		else if (connectionState === ConnectionState.loggedOut) {
			// Nothing
		}
		else if (connectionState === ConnectionState.connecting) {
			if (this.state.serverObject) {
				console.error('State "connecting" was received when the server is already connected.')
			}
			const serverObject = await StartServer(dispatch, this.props.websocketUrl)
			this.setState({serverObject})
			serverObject.processServerCommandQueue(this.props.serverCommandQueue)
		}
		else if (connectionState === ConnectionState.connected) {
			// Nothing
		}
		else if (connectionState === ConnectionState.errored) {
			// Nothing
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>) {
		if (prevProps.connectionState !== this.props.connectionState) {
			this.onConnectionStateChanged(this.props.connectionState).then().catch(console.error)
		}
		if (prevProps.serverCommandQueue !== this.props.serverCommandQueue) {
			if (this.state.serverObject) {
				this.state.serverObject.processServerCommandQueue(this.props.serverCommandQueue)
			}
		}
	}

  componentDidMount = ()=>{
		this.onConnectionStateChanged(this.props.connectionState).then().catch(console.error)
	}

  componentWillUnmount = ()=>{
		if (this.state.serverObject) {
			this.state.serverObject.disconnect()
		}
  }

	render() {
		return (<>{this.props.children}</>)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerComponent)
