"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var package_json_1 = require("./package.json");
/*
    Version update guidelines:
    - Patch version increased for changes that do not change existing
    behavior i.e. do not change the order of EventTypes, do not change
    the signature of already existing EventObjects.
    - Minor version is increased if the above mentionedrequirements
    are not met but the majority of the API is intact.
    - Major version is increased when the API is significantly changed.
*/


exports.Version = package_json_1.version;
exports.ZeroKey = '00000000-0000-0000-0000-000000000000';
var WsCloseCode;

(function (WsCloseCode) {
  // Standard
  WsCloseCode[WsCloseCode["CLOSE_NORMAL"] = 1000] = "CLOSE_NORMAL";
  WsCloseCode[WsCloseCode["CLOSE_GOING_AWAY"] = 1001] = "CLOSE_GOING_AWAY";
  WsCloseCode[WsCloseCode["CLOSE_PROTOCOL_ERROR"] = 1002] = "CLOSE_PROTOCOL_ERROR";
  WsCloseCode[WsCloseCode["CLOSE_UNSUPPORTED"] = 1003] = "CLOSE_UNSUPPORTED";
  /*reserved = 1004,*/

  /*CLOSED_NO_STATUS = 1005,*/

  /*CLOSE_ABNORMAL = 1006,*/

  WsCloseCode[WsCloseCode["UNSUPPORTED_PAYLOAD"] = 1007] = "UNSUPPORTED_PAYLOAD";
  WsCloseCode[WsCloseCode["POLICY_VIOLATION"] = 1008] = "POLICY_VIOLATION";
  WsCloseCode[WsCloseCode["CLOSE_TOO_LARGE"] = 1009] = "CLOSE_TOO_LARGE";
  WsCloseCode[WsCloseCode["MANDATORY_EXTENSION"] = 1010] = "MANDATORY_EXTENSION";
  WsCloseCode[WsCloseCode["SERVER_ERROR"] = 1011] = "SERVER_ERROR";
  WsCloseCode[WsCloseCode["SERVICE_RESTART"] = 1012] = "SERVICE_RESTART";
  WsCloseCode[WsCloseCode["TRY_LATER"] = 1013] = "TRY_LATER";
  WsCloseCode[WsCloseCode["BAD_GATEWAY"] = 1014] = "BAD_GATEWAY";
  WsCloseCode[WsCloseCode["TLS"] = 1015] = "TLS"; // Application codes

  WsCloseCode[WsCloseCode["BAD_REQUEST"] = 4400] = "BAD_REQUEST";
  WsCloseCode[WsCloseCode["UNAUTHORIZED"] = 4401] = "UNAUTHORIZED";
  WsCloseCode[WsCloseCode["NOT_FOUND"] = 4404] = "NOT_FOUND";
  WsCloseCode[WsCloseCode["NOT_IMPLEMENTED"] = 4501] = "NOT_IMPLEMENTED";
  WsCloseCode[WsCloseCode["VERSION_NOT_SUPPORTED"] = 4601] = "VERSION_NOT_SUPPORTED";
})(WsCloseCode = exports.WsCloseCode || (exports.WsCloseCode = {}));

var Client;

(function (Client) {
  var EventType;

  (function (EventType) {
    EventType[EventType["_any"] = 0] = "_any";
    EventType[EventType["search"] = 1] = "search";
    EventType[EventType["findAll"] = 2] = "findAll";
    EventType[EventType["pin"] = 3] = "pin";
    EventType[EventType["unpin"] = 4] = "unpin";
    EventType[EventType["unpinAll"] = 5] = "unpinAll";
    EventType[EventType["listPinned"] = 6] = "listPinned";
    EventType[EventType["update"] = 7] = "update";
    EventType[EventType["delete"] = 8] = "delete";
    EventType[EventType["fetch"] = 9] = "fetch";
    EventType[EventType["fetchAbort"] = 10] = "fetchAbort";
    EventType[EventType["upload"] = 11] = "upload";
    EventType[EventType["uploadFragment"] = 12] = "uploadFragment";
    EventType[EventType["uploadAbort"] = 13] = "uploadAbort";
    EventType[EventType["download"] = 14] = "download";
    EventType[EventType["downloadAbort"] = 15] = "downloadAbort";
    EventType[EventType["logout"] = 16] = "logout";
  })(EventType = Client.EventType || (Client.EventType = {}));

  function is(action, type) {
    return action.type === type;
  }

  Client.is = is;
})(Client = exports.Client || (exports.Client = {}));

var Server;

(function (Server) {
  var EventType;

  (function (EventType) {
    EventType[EventType["_any"] = 0] = "_any";
    EventType[EventType["message"] = 1] = "message";
    EventType[EventType["data"] = 2] = "data";
    EventType[EventType["pinned"] = 3] = "pinned";
    EventType[EventType["searchResult"] = 4] = "searchResult";
    EventType[EventType["download"] = 5] = "download";
    EventType[EventType["downloadFragment"] = 6] = "downloadFragment";
    EventType[EventType["downloadAborted"] = 7] = "downloadAborted";
    EventType[EventType["uploadFailed"] = 8] = "uploadFailed";
    EventType[EventType["dataNotFound"] = 9] = "dataNotFound";
    EventType[EventType["binaryDataNotFound"] = 10] = "binaryDataNotFound";
  })(EventType = Server.EventType || (Server.EventType = {}));

  function is(action, type) {
    return action.type === type;
  }

  Server.is = is;
})(Server = exports.Server || (exports.Server = {}));