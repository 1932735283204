import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { State as GlobalState } from '../reducer'
import { ActionObject, ServerAction } from '../actions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'


interface Props {
	onLoggedIn:(protocol:string)=>void
	connectionStatusMessage:string
}
interface State {
	userName:string
	password:string
	pending:boolean
	message:string
}

const mapStateToProps = (state:GlobalState)=>{
	return {
		connectionStatusMessage: state.connectionStatusMessage
	}
}
const mapDispatchToProps = (dispatch:Dispatch<ActionObject>)=>{
	return {
		onLoggedIn: (protocol:string)=>dispatch(ServerAction.connectionStateConnecting(protocol))
	}
}

const DEV = process.env.NODE_ENV === 'development'

class Login extends Component<Props, State>  {

	state:State = {
		userName: '',
		password: '',
		pending: false,
		message: '',
	}

	submitAsync = async ()=>{
		this.setState({pending: true})
		const response = await fetch('/api/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
			},
			credentials: DEV ? 'include' : 'same-origin',
			body: JSON.stringify({userName:this.state.userName, password:this.state.password}),
		})

		if (response.status === 200) {
			const protocol = await response.text()
			this.props.onLoggedIn(protocol)
		}
		else {
			this.setState({message: response.statusText})
		}
	}

	submit = ()=>{
		this.submitAsync()
		.then(()=>{
			this.setState({pending: false})
		})
		.catch((error:any)=>{
			this.setState({pending: false, message: error.message})
		})
	}

	setUserName = (event:React.ChangeEvent<HTMLInputElement>)=>{
		this.setState({userName:event.target.value})
	}

	setPassword = (event:React.ChangeEvent<HTMLInputElement>)=>{
		this.setState({password:event.target.value})
	}

	render() {
		return (
			<div className='login-block'>
				<div className='login-element'>
					<TextField variant='outlined' onChange={this.setUserName} label='User Name'></TextField>
				</div>
				<div className='login-element'>
					<TextField variant='outlined' onChange={this.setPassword} label='Password' type='password'></TextField>
				</div>
				<div className='login-element login-button'>
					<Button variant='outlined' onClick={this.submit}>Log in</Button>
				</div>
				{this.props.connectionStatusMessage && <div className='connection-status'>{this.props.connectionStatusMessage}</div>}
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
