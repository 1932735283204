import { DatumObject, BinaryDatumObject, Client, BinaryDatumObjectHeader } from 'datum-api'
import { Action, ActionType, ServerAction, ActionObject } from '../actions'
import { Store, Dispatch } from 'redux'

const SearchCommand = (text:string):Client.EventObject<Client.EventType.search>=>{
	return {
		type: Client.EventType.search,
		search: text,
	}
}

const PinCommand = (keys:string[]):Client.EventObject<Client.EventType.pin>=>{
	return {
		type: Client.EventType.pin,
		keys: keys,
	}
}

const UnpinCommand = (keys:string[]):Client.EventObject<Client.EventType.unpin>=>{
	return {
		type: Client.EventType.unpin,
		keys: keys,
	}
}

const UnpinAllCommand = ():Client.EventObject<Client.EventType.unpinAll>=>{
	return {
		type: Client.EventType.unpinAll,
	}
}

const UpdateCommand = (element:DatumObject):Client.EventObject<Client.EventType.update>=>{
	return {
		type: Client.EventType.update,
		datum: element,
	}
}

const DeleteCommand = (key:string):Client.EventObject<Client.EventType.delete>=>{
	return {
		type: Client.EventType.delete,
		keys: [key],
	}
}

const FetchCommand = (keys:string[]):Client.EventObject<Client.EventType.fetch>=>{
	return {
		type: Client.EventType.fetch,
		keys: keys,
	}
}

const FetchAbortCommand = (keys:string[]):Client.EventObject<Client.EventType.fetchAbort>=>{
	return {
		type: Client.EventType.fetchAbort,
		keys: keys,
	}
}

export const FindAllCommand = ():Client.EventObject<Client.EventType.findAll>=>{
	return {
		type: Client.EventType.findAll,
	}
}

const DownloadBinaryCommand = (keys:string[]):Client.EventObject<Client.EventType.download>=>{
	return {
		type: Client.EventType.download,
		keys: keys,
	}
}

export const ListPinnedCommand = ():Client.EventObject<Client.EventType.listPinned>=>{
	return {
		type: Client.EventType.listPinned,
	}
}

const UploadBinaryCommand = (binaryDatum:BinaryDatumObject):Client.EventObject<Client.EventType.upload>=>{
	throw new Error('Not implemeted')
	return {
		type: Client.EventType.upload,
		header: {} as BinaryDatumObjectHeader,
	}
}

const AbortUploadBinaryCommand = (keys:string[]):Client.EventObject<Client.EventType.uploadAbort>=>{
	throw new Error('Not implemeted')
	return {
		type: Client.EventType.uploadAbort,
		keys: keys,
	}
}

const LogOutCommand = ():Client.EventObject<Client.EventType.logout>=>{
	return {
		type: Client.EventType.logout,
	}
}

const dispatch = (store:Store, action:ActionObject)=>{
	Promise.resolve().then(()=>{
		store.dispatch(action)
	})
}

export const BackendMiddleware = (store:Store)=>(next:Dispatch<ActionObject>)=>(action:ActionObject)=>{
	if (Action.is(action, ActionType.search)) {
		dispatch(store, ServerAction.enqueueCommand(SearchCommand(action.searchString)))
	}
	else if (Action.is(action, ActionType.deleteCard)) {
		dispatch(store, ServerAction.enqueueCommand(DeleteCommand(action.datumId)))
	}
	else if (Action.is(action, ActionType.createCard)) {
		dispatch(store, ServerAction.enqueueCommand(UpdateCommand(action.datum)))
	}
	else if (Action.is(action, ActionType.updateCards)) {
		dispatch(store, ServerAction.enqueueCommands(action.data.map(datum=>UpdateCommand(datum))))
	}
	else if (Action.is(action, ActionType.pinCard)) {
		dispatch(store, ServerAction.enqueueCommand(PinCommand([action.datumId])))
	}
	else if (Action.is(action, ActionType.unpinCard)) {
		dispatch(store, ServerAction.enqueueCommand(UnpinCommand([action.datumId])))
	}
	else if (Action.is(action, ActionType.fetchCard)) {
		dispatch(store, ServerAction.enqueueCommand(FetchCommand([action.datumId])))
	}
	else if (Action.is(action, ActionType.getBinary)) {
		dispatch(store, ServerAction.enqueueCommand(DownloadBinaryCommand(action.binaryDatumIds)))
	}
	else if (Action.is(action, ActionType.uploadBinary)) {
		dispatch(store, ServerAction.enqueueCommand(UploadBinaryCommand(action.binaryDatum)))
	}
	else if (Action.is(action, ActionType.findAll)) {
		dispatch(store, ServerAction.enqueueCommand(FindAllCommand()))
	}
	else if (Action.is(action, ActionType.logOut)) {
		dispatch(store, ServerAction.enqueueCommand(LogOutCommand()))
	}
	return next(action)
}
