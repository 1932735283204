import React, { Component } from 'react'
import { State as GlobalState } from '../reducer'
import { connect } from 'react-redux'

function textToName(text:string):string {
	const name = text.substr(0, 20)
	const spaceIndex = name.lastIndexOf(' ')
	if (spaceIndex > 5) {
		return name.substr(0, spaceIndex)
	}
	else {
		return name
	}
}
function nextName(name:string, otherNames:string[]):string {
	function isNameUnique(name:string, postfix:string) {
		return ! ((name + postfix) in otherNames)
	}
	var postfix = ''
	var index = 0
	while (!isNameUnique(name, postfix)) {
		index++
		postfix = ' '+index
	}
	return name + postfix
}
function nextNameForText(text:string, otherNames:string[]) {
	const name = textToName(text)
	return nextName(name, otherNames)
}

interface Props {
	names:string[]
}

const mapStateToProps = (state:GlobalState)=>{
	return {
	}
}
const mapDispatchToProps = (dispatch:any)=>{
	return {
	}
}

interface State {
	dropping:number
	hover:boolean
}

class FileDrop extends Component<Props, State> {
	static defaultProps = {
		names: [],
	}

	state = {
		dropping: 0,
		hover: false,
	}

	render() {
		return (
			<div />
		)
	}

	onDragEnter = (e:React.DragEvent<HTMLTextAreaElement>)=>{
		this.setState({hover:true})
	}
	onDragLeave = (e:React.DragEvent<HTMLTextAreaElement>)=>{
		this.setState({hover:false})
	}
	onDragOver = (e:React.DragEvent<HTMLTextAreaElement>)=>{
		e.preventDefault()
	}
	onDrop = (e:React.DragEvent<HTMLTextAreaElement>)=>{
		this.setState({hover:false}) 
		e.stopPropagation()
		e.preventDefault()
		const items = e.dataTransfer.items
		if (items) {
			this.fileDropStarted()
			this.processDrop(items).then(this.fileDropFinished).catch(this.fileDropFinished)
		}
	}
	
	processDrop = async (items:DataTransferItemList)=>{
		const names = this.props.names
		const result = []
		for (var i = 0; i < items.length; i++) {
			const item = items[i]
			if (item.kind === 'string') {
				const text = await new Promise((resolve)=>item.getAsString(resolve)) as string
				const name = nextNameForText(text, names)
				names.push(name)
				result.push({
					name: name,
					type: item.type,
					bytes: text,
				})
			}
			else if (item.kind === 'file') {
				const file = item.getAsFile() as File
				const reader = new FileReader()
				const buffer = await new Promise((resolve, reject)=>{
					reader.onload = ()=>resolve(reader.result as ArrayBuffer)
					reader.onerror = reject
					reader.onabort = reject
					reader.readAsArrayBuffer(file)
				}) as ArrayBuffer
				const name = nextName(file.name, names)
				names.push(file.name)
				result.push({
					name: name,
					type: file.type,
					bytes: Buffer.from(buffer),
				})
			}
		}
		return result
	}
	fileDropFinished = (newData:DataTransferItemList|any)=>{
		/*
		const object = this.state.object
		this.setState({
			droppedFilesReading: false,
			object: {
				...object,
				data: [
					...object.data,
					...newData,
				],
			}
		})*/
	}
	fileDropStarted = ()=>{
		this.setState({hover:false})
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FileDrop)
