import { DatumObject, PinnedDatum, BinaryDatumObject } from 'datum-api'
import { OutputParams } from 'query-string'
import { Action as A } from 'redux'

export enum ActionType {
	// Card actions
	deleteCard,
	createCard,
	updateCards,
	pinCard,
	unpinCard,
	search,
	fetchCard,
	findAll, // temporary
	dataNotFound,

	getBinary,
	uploadBinary,
	binaryLoaded,
	logOut,

	// View actions
	setFocusedCard,
	setWindowHash,

	// Reserved to services
	setDisplayedCards,
	setPinnedCards,
	connectionStatusMessage,
	serverVersion,
	connectionStateLoggedOut,
	connectionStateConnecting,
	connectionStateConnected,
	connectionStateErrored,
	serverEnqueueCommands,
	serverDequeueCommands,

	_any,
}

interface Map<T> {
	[key:string]:T
}

interface Types {
	[ActionType.deleteCard]:{datumId:string}
	[ActionType.createCard]:{datum:DatumObject}
	[ActionType.updateCards]:{data:DatumObject[]}
	[ActionType.pinCard]:{datumId:string},
	[ActionType.unpinCard]:{datumId:string},
	[ActionType.search]:{searchString:string}
	[ActionType.fetchCard]:{datumId:string}
	[ActionType.findAll]:{}
	[ActionType.dataNotFound]:{datumIds:string[]}
	[ActionType.getBinary]:{binaryDatumIds:string[]}
	[ActionType.uploadBinary]:{binaryDatum:BinaryDatumObject}
	[ActionType.binaryLoaded]:{binaryDatum:BinaryDatumObject}
	[ActionType.logOut]:{}
	[ActionType.setFocusedCard]:{datumId:string}
	[ActionType.setWindowHash]:{page:string}

	[ActionType.setDisplayedCards]:{datumIds:string[]}
	[ActionType.setPinnedCards]:{pinnedData:PinnedDatum[]}
	[ActionType.serverVersion]:{server:string, api:string}
	[ActionType.connectionStatusMessage]:{message:string}
	[ActionType.connectionStateLoggedOut]:{}
	[ActionType.connectionStateConnecting]:{url:string}
	[ActionType.connectionStateConnected]:{}
	[ActionType.connectionStateErrored]:{}
	[ActionType.serverEnqueueCommands]:{commands:any[]}
	[ActionType.serverDequeueCommands]:{count:number}

	[ActionType._any]:Map<any>
}

export type ActionObject<T extends keyof Types=ActionType._any> = (A<ActionType> | A<T>) & Types[T]

function isOfType<T extends keyof Types>(action:ActionObject, type:T): action is ActionObject<T> {
	return action.type === type
}

export const Action = {
	is: isOfType,

	deleteCard: (datumId:string):ActionObject<ActionType.deleteCard>=>{
		return {type: ActionType.deleteCard, datumId: datumId}
	},

	createCard: (datum:DatumObject):ActionObject<ActionType.createCard>=>{
		return {type: ActionType.createCard, datum: datum}
	},

	updateCards: (data:DatumObject[]):ActionObject<ActionType.updateCards>=>{
		return {type: ActionType.updateCards, data: data}
	},
	search: (text:string):ActionObject<ActionType.search>=>{
		return {type: ActionType.search, searchString: text}
	},
	findAll: ():ActionObject=>{
		return {type: ActionType.findAll}
	},
	dataNotFound: (datumIds:string[]):ActionObject<ActionType.dataNotFound>=>{
		return {type: ActionType.dataNotFound, datumIds: datumIds}
	},

	setFocusedCard: (key:string):ActionObject<ActionType.setFocusedCard>=>{
		return {type: ActionType.setFocusedCard, datumId: key}
	},

	setWindowHash: (hash:string):ActionObject<ActionType.setWindowHash>=>{
		return {type: ActionType.setWindowHash, page:hash}
	},

	logOut: ():ActionObject<ActionType.logOut>=>{
		return {type: ActionType.logOut}
	},

	setDisplayedCards: (datumIds:string[]):ActionObject<ActionType.setDisplayedCards>=>{
		return {type: ActionType.setDisplayedCards, datumIds: datumIds}
	},

	pinCard: (key:string):ActionObject<ActionType.pinCard>=>{
		return {type: ActionType.pinCard, datumId: key}
	},

	unpinCard: (key:string):ActionObject<ActionType.unpinCard>=>{
		return {type: ActionType.unpinCard, datumId: key}
	},

	fetch: (key:string):ActionObject<ActionType.fetchCard>=>{
		return {type: ActionType.fetchCard, datumId: key}
	}

}

export const ServerAction = {

	setPinnedCards: (pinned:PinnedDatum[]):ActionObject<ActionType.setPinnedCards>=>{
		return {type: ActionType.setPinnedCards, pinnedData: pinned}
	},

	serverVersion: (serverVersion:string, apiVersion:string):ActionObject<ActionType.serverVersion>=>{
		return {type: ActionType.serverVersion, server: serverVersion, api: apiVersion}
	},

	connectionStatusMessage: (message:string):ActionObject<ActionType.connectionStatusMessage>=>{
		return {type: ActionType.connectionStatusMessage, message: message}
	},

	enqueueCommands: (commands:any[]):ActionObject<ActionType.serverEnqueueCommands>=>{
		return {type: ActionType.serverEnqueueCommands, commands: commands}
	},

	enqueueCommand: (command:any):ActionObject<ActionType.serverEnqueueCommands>=>{
		return {type: ActionType.serverEnqueueCommands, commands: [command]}
	},

	dequeueCommands: (count:number):ActionObject<ActionType.serverDequeueCommands>=>{
		return {type: ActionType.serverDequeueCommands, count: count}
	},

	connectionStateLoggedOut: ():ActionObject<ActionType.connectionStateLoggedOut>=>{
		return {type: ActionType.connectionStateLoggedOut}
	},

	connectionStateConnecting: (url:string):ActionObject<ActionType.connectionStateConnecting>=>{
		return {type: ActionType.connectionStateConnecting, url}
	},

	connectionStateConnected: ():ActionObject<ActionType.connectionStateConnected>=>{
		return {type: ActionType.connectionStateConnected}
	},

	connectionStateErrored: ():ActionObject<ActionType.connectionStateErrored>=>{
		return {type: ActionType.connectionStateErrored}
	}
}
