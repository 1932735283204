import React, { Component } from 'react'
import { Action } from '../actions'
import { connect } from 'react-redux'
import { DatumObject } from 'datum-api'
import { State as GlobalState } from '../reducer'
import FileDrop from './filedrop'
import {
	OpenInNew,
	Bookmark,
	BookmarkBorder,
	Delete,
} from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'

interface OwnProps {
	datum:DatumObject
	onCancel?:()=>void
	onChange?:(text:string)=>void
}
interface StateProps {
	pinned:boolean
}
interface DispatchProps {
	onSubmit: (datum:DatumObject)=>void
	onDelete:()=>void
	onExpand:()=>void
	onPin:()=>void
	onUnpin:()=>void
}

interface Props extends OwnProps, StateProps, DispatchProps {}

interface State {
	dropping:boolean
	datum:DatumObject
	hover:boolean
	editing:boolean
}

const mapStateToProps = (state:GlobalState, props:OwnProps):StateProps=>{
	return {
		pinned: state.nextPinnedCards.includes(props.datum.key),
	}
}
const mapDispatchToProps = (dispatch:any, props:OwnProps)=>{
	return {
		onSubmit: (datum:DatumObject)=>dispatch(Action.updateCards([datum])),
		onDelete: ()=>dispatch(Action.deleteCard(props.datum.key)),
		onExpand: ()=>dispatch(Action.setFocusedCard(props.datum.key)),
		onPin: ()=>dispatch(Action.pinCard(props.datum.key)),
		onUnpin: ()=>dispatch(Action.unpinCard(props.datum.key)),
	}
}

class Card extends Component<Props, State> {
	state = {
		dropping: false,
		datum: this.props.datum,
		hover: false,
		editing: false,
	}

	componentDidUpdate(prevProps:Readonly<Props>) {
		if (prevProps.datum != this.props.datum) {
			if (!this.state.editing) {
				this.setState({datum: this.props.datum})
			}
		}
	}

	onTextChange = (event:React.ChangeEvent<HTMLTextAreaElement>)=>{
		const text = event.target.value
		const datum = this.state.datum
		this.setState({datum: {...datum, text: text}})
		this.props.onChange && this.props.onChange(text)
	}

	onBlur = ()=>{
		this.onSubmit()
	}

	onSubmit = ()=>{
		this.props.onSubmit(this.state.datum)
		this.setState({editing:false})
	}

	onCancel = ()=>{
		this.props.onCancel && this.props.onCancel()
	}

	startEditing = ()=>{
		this.setState({editing:true})
	}

	togglePin = ()=>{
		if (this.props.pinned) {
			this.props.onUnpin()
		}
		else {
			this.props.onPin()
		}
	}

	render() {
		return(
			<div className='container' onMouseLeave={()=>this.setState({hover:false})} onMouseOver={()=>this.setState({hover:true})}>
				<Paper className='element'>
						<TextField
							variant='outlined'
							value={this.state.datum.text}
							multiline
							rowsMax={this.state.editing?'':'4'}
							className='card-text-field card-text'
							placeholder='Add'
							onChange={this.onTextChange}
							onBlur={this.onBlur}
							onFocus={this.startEditing}
						/>
					{this.state.dropping && <div className='data-dropping'><img alt='+' src='plus-circle-solid.svg'/></div>}
					{this.props.pinned && <Bookmark className='pinned-card-label' color='secondary' />}
					<FileDrop />
				</Paper>

				<div className='card-extra-action'>
						{ (this.state.hover || this.state.editing) &&
							<div className='card-control'>
								<div className='card-control-row'>
									<IconButton className='card-control-element' onClick={this.togglePin}>
										{this.props.pinned?<Bookmark />:<BookmarkBorder />}
									</IconButton>
									<IconButton className='card-control-element' onClick={this.props.onExpand}>
										<OpenInNew />
									</IconButton>
									<IconButton className='card-extra-control-element' onClick={this.props.onDelete}>
										<Delete />
									</IconButton>
								</div>
							</div>
							}
					</div>
			</div>
		)
	}
}

export default connect<StateProps, DispatchProps, OwnProps, GlobalState>(mapStateToProps, mapDispatchToProps)(Card)
