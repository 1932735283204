
import { ServerAction, ActionObject } from '../actions'
import { Server, Version as ApiVersion } from 'datum-api'
import { backOff, IBackOffRequest } from 'exponential-backoff'
import { Dispatch } from 'redux'
import { Context, CreateContext } from './context'
import { ListPinnedCommand } from './middleware'
import Bson from 'bson'
const BSON = new Bson.BSON()

export function WebsocketMessageToObject(message:MessageEvent):any {
	const data:any = message.data
	if (typeof data === 'string') {
		return JSON.parse(data)
	}
	else if (data instanceof ArrayBuffer) {
		const buffer = Buffer.from(data)
		return BSON.deserialize(buffer, {promoteBuffers:true})
	}
	else {
		throw new Error('Unexpected type of websocket message.')
	}
}

const ObjectToServerEvent = (input:any):Server.EventObject=>{
	// TODO: message format checks go here
	return {
		...input,
		type: input['type'],
	}
}

const processServerEvent = (servEvent:Server.EventObject, context:Context)=>{
	const handlers = context.handlers;
	const handler = handlers[servEvent.type]
	if (handler) {
		handler(servEvent)
	}
}

export interface ServerObject {
	disconnect:()=>void
	processServerCommandQueue:(queue:any[])=>void
}

function connected(connection:WebSocket, dispatch:Dispatch<ActionObject>):ServerObject {
	// Prefetch pinned cards
	dispatch(ServerAction.enqueueCommand(ListPinnedCommand()))

	connection.onerror = (event:Event)=>{
		dispatch(ServerAction.connectionStateErrored())
	}
	const context:Context = CreateContext(dispatch)

	connection.onmessage = (websocketMessage:MessageEvent)=>{
		const messageObject = WebsocketMessageToObject(websocketMessage)
		const serverEvent:Server.EventObject = ObjectToServerEvent(messageObject)
		processServerEvent(serverEvent, context)
	}

	connection.onclose = (event:CloseEvent)=>{
		if (event.reason) {
			dispatch(ServerAction.connectionStatusMessage(event.reason))
		}
		dispatch(ServerAction.connectionStateLoggedOut())
	}

	const processServerCommandQueue = (serverCommandQueue:any[])=>{
		const commandCount = serverCommandQueue.length
		if (commandCount > 0) {
			serverCommandQueue.forEach((command)=>{
				connection.send(BSON.serialize(command))
			})
			dispatch(ServerAction.dequeueCommands(commandCount))
		}	
	}
	const disconnect = ()=>{
		connection.close()
	}

	dispatch(ServerAction.connectionStateConnected())
	return {
		disconnect,
		processServerCommandQueue,
	}
}

export default function start(dispatch:Dispatch<ActionObject>, url:string) {
	const backOffOptions = {
		numOfAttempts: 10,
		startingDelay: 100, //ms
		timeMultiple: 2,
	}

	const connect = async ():Promise<ServerObject>=>{
		return new Promise<ServerObject>((resolve, reject)=>{
			const ws = new WebSocket(url+'?v='+ApiVersion)
			ws.binaryType = 'arraybuffer'
			ws.onopen = ()=>{
				const serverObject:ServerObject = connected(ws, dispatch)
				resolve(serverObject)
			}
			ws.onerror = (event:Event)=>{
				reject(event)
			}
		})
	}

	const retry = (e:any, attemptNumber:number)=>{
		return true
	}

	const request:IBackOffRequest<ServerObject> = {
		fn: connect,
		retry: retry,
	}
	return backOff(request, backOffOptions)//.then(()=>{}).catch(console.error)
}
