import React, { ReactElement, Component } from 'react'
import { State as GlobalState } from '../reducer'
import { connect } from 'react-redux'
import { ActionObject, Action } from '../actions'
import { DatumObject } from 'datum-api'

interface OwnProps {
	datumId:string
	Loaded:(datum:DatumObject)=>ReactElement<{}>
	Loading:(datumId:string)=>ReactElement<{}>
	Missing:(datumId:string)=>ReactElement<{}>
}
interface StateProps {
	datum:DatumObject | undefined
	datumNotFound:boolean
}
interface DispatchProps {
	fetch:()=>void
}
interface Props extends OwnProps, StateProps, DispatchProps {}

const mapStateToProps = (state:GlobalState, props:OwnProps):StateProps=>{
	return {
		datum: state.data.find(d=>d.key===props.datumId),
		datumNotFound: state.dataNotFound.includes(props.datumId),
	}
}
const mapDispatchToProps = (dispatch:React.Dispatch<ActionObject>, props:OwnProps):DispatchProps=>{
	return {
		fetch: ()=>dispatch(Action.fetch(props.datumId)),
	}
}

class FetchedCard extends Component<Props, {}> {
	componentDidMount() {
		if (!this.props.datum && !this.props.datumNotFound) {
			this.props.fetch()
		}
	}
	componentDidUpdate(prevProps:Readonly<Props>) {
		if (prevProps.datumId !== this.props.datumId) {
			if (!this.props.datum && !this.props.datumNotFound) {
				this.props.fetch()
			}
		}
	}
	render() {
		if (this.props.datum) {
			return this.props.Loaded(this.props.datum as DatumObject)
		}
		else if (this.props.datumNotFound) {
			return this.props.Missing(this.props.datumId)
		}
		else {
			return this.props.Loading(this.props.datumId)
		}
	}
}

export default connect<StateProps, DispatchProps, OwnProps, GlobalState>(mapStateToProps, mapDispatchToProps)(FetchedCard)
