import React, { Component } from 'react'
import { Action } from '../actions'
import { connect } from 'react-redux'
import Card from './card'
import CardLoader from './cardloader'
import { State as GlobalState } from '../reducer'
import { DatumObject } from 'datum-api'
import FirstCard from './firstcard'
import Button from '@material-ui/core/Button'

interface OwnProps {}
interface StateProps {
	pinnedCards:string[]
	recentlyAddedData:DatumObject[]
	displayedCards:string[]
}
interface DispatchProps {
	submit:(card:DatumObject)=>void
	search:(text:string)=>void
	logOut:()=>void
	showAll:()=>void
	clearSearch:()=>void
}

interface Props extends OwnProps, StateProps, DispatchProps {}

interface State {}

const mapStateToProps = (state:GlobalState):StateProps=>{
	return {
		pinnedCards: state.pinnedCards,
		displayedCards: state.displayedCards,
		recentlyAddedData: state.recentlyAddedData,
	}
}
const mapDispatchToProps = (dispatch:any):DispatchProps=>{
	return {
		submit: (datum:DatumObject)=>dispatch(Action.updateCards([datum])),
		search: (text:string)=>dispatch(Action.search(text)),
		logOut: ()=>dispatch(Action.logOut()),
		showAll: ()=>dispatch(Action.findAll()),
		clearSearch: ()=>dispatch(Action.setDisplayedCards([]))
	}
}

class CardContainer extends Component<Props, State> {
	render() {
		const displayedCards = this.props.displayedCards.filter(c=>!this.props.pinnedCards.includes(c))
		return (
			<div>
				<div className='button-logout'>
					<Button variant='outlined' onClick={this.props.logOut}>Log out</Button>
					<Button variant='outlined' onClick={this.props.showAll}>Show All</Button>
				</div>
				<div className='content'>
					<div className='content-search'>
						<FirstCard onChange={this.props.search} onClear={this.props.clearSearch}/>
					</div>
					<div className='content-newly-added'>
						{this.props.recentlyAddedData.map(datum=>
							<Card key={datum.key} datum={datum} />
						)}
					</div>
					<div className='content-pinned'>
						{this.props.pinnedCards.map(datumId=>
							<CardLoader key={datumId} datumId={datumId} />
						)}
					</div>
					<div className='midpart'>
						{displayedCards.map(datumId=>
							<CardLoader key={datumId} datumId={datumId} />
						)}
					</div>
				</div>
			</div>
		)
	}
}

export default connect<StateProps, DispatchProps, OwnProps, GlobalState>(mapStateToProps, mapDispatchToProps)(CardContainer)
