import { ApiHandler, UnboundHandlers } from './api'
import { Server } from 'datum-api'
import { Dispatch } from 'redux'

type HandlerMap = {
	[api in Server.EventType]:ApiHandler
}

export interface Context {
	handlers:HandlerMap
}

export function CreateContext(dispatch:Dispatch):Context {
	const handlers = UnboundHandlers.reduce<HandlerMap>((handlers, unboundHandler)=>{
		const [type, handler] = unboundHandler(dispatch)
		handlers[type] = handler
		return handlers
	}, {} as HandlerMap)

	const context = {
		handlers,
	}
	return context
}
