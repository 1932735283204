import { ActionType, ActionObject, Action } from './actions'
import { DatumObject } from 'datum-api'
import { OutputParams, stringify } from 'query-string'

export enum ConnectionState {
	pending, // Inital state. Will try to connect.
	loggedOut, // Logged out. Need authorization to preceed.
	connecting, // Logged in. Connecting to the websocket.
	connected,
	errored, // Connection errored. Retrying.
}

export class State {
	data:DatumObject[] = []
	recentlyAddedData:DatumObject[] = []
	pinnedCards:string[] = [] // This list is updated when the view is refreshed (e.g. new search)
	nextPinnedCards:string[] = [] // This reflects the actual list of pinned cards
	displayedCards:string[] = []
	serverCommandQueue:any[] = []
	dataNotFound:string[] = []
	page:string = ''
	connectionState:ConnectionState = ConnectionState.pending
	websocketUrl:string = ''
	serverVersion = {server:'x.x.x', api:'x.x.x'}
	connectionStatusMessage = ''
}

const initialSate:State = new State()

const dataReducer=(acc:any, cur:any, i:number)=>{
	acc[cur.key] = cur
	return acc
}
const mergeData=(a:any, b:any)=>{
	return Object.values(Object.assign(a.reduce(dataReducer, {}), b.reduce(dataReducer, {})))
}

export default (state:State|null=null, action:ActionObject) => {
	if (state === null) {
		return initialSate
	}
	if (!action) {
		throw Error('An "action" provided is evaluated to false.')
	}
	
	switch (action.type) {
		case ActionType.serverVersion : {
			const typedAction = action as ActionObject<ActionType.serverVersion>
			return {
				...state,
				serverVersion: {
					server: typedAction.server,
					api: typedAction.api,
				}
			}
		}

		case ActionType.search: {
			const typedAction = action as ActionObject<ActionType.search>
			return {
				...state,
				pinnedCards: [...state.nextPinnedCards],
				recentlyAddedData: [],
			}
		}

		case ActionType.deleteCard: {
			const typedAction = action as ActionObject<ActionType.deleteCard>
			return {
				...state,
				data: state.data.filter((datum:DatumObject)=>datum.key !== typedAction.datumId),
				recentlyAddedData: state.recentlyAddedData.filter((datum:DatumObject)=>datum.key !== typedAction.datumId),
				pinnedCards: state.pinnedCards.filter(c=>c!==typedAction.datumId),
				nextPinnedCards: state.nextPinnedCards.filter(c=>c!==typedAction.datumId),
				displayedCards: state.displayedCards.filter(c=>c!==typedAction.datumId),
			}
		}

		case ActionType.createCard: {
			const typedAction = action as ActionObject<ActionType.createCard>
			return {
				...state,
				recentlyAddedData: [typedAction.datum, ...state.recentlyAddedData],
			}
		}

		case ActionType.updateCards: {
			const typedAction = action as ActionObject<ActionType.updateCards>
			return {
				...state,
				data: mergeData(state.data, typedAction.data),
				blockBeingEdited: null,
			}
		}

		case ActionType.dataNotFound: {
			const typedAction = action as ActionObject<ActionType.dataNotFound>
			return {
				...state,
				dataNotFound: [...state.dataNotFound, ...typedAction.datumIds],
			}
		}
		
		case ActionType.pinCard: {
			const typedAction = action as ActionObject<ActionType.pinCard>
			return {
				...state,
				nextPinnedCards: [...state.nextPinnedCards, typedAction.datumId],
			}
		}

		case ActionType.unpinCard: {
			const typedAction = action as ActionObject<ActionType.unpinCard>
			return {
				...state,
				nextPinnedCards: state.nextPinnedCards.filter(c=>c!==typedAction.datumId),
			}
		}
		
		case ActionType.setDisplayedCards: {
			const typedAction = action as ActionObject<ActionType.setDisplayedCards>
			return {
				...state,
				displayedCards: typedAction.datumIds,
				pinnedCards: [...state.nextPinnedCards],
				recentlyAddedData: [],
			}
		}
		
		case ActionType.setPinnedCards: {
			const typedAction = action as ActionObject<ActionType.setPinnedCards>
			const pinnedIds = typedAction.pinnedData.map(i=>i.key)
			return {
				...state,
				pinnedCards: pinnedIds,
				nextPinnedCards: pinnedIds,
			}
		}

		case ActionType.connectionStatusMessage: {
			const typedAction = action as ActionObject<ActionType.connectionStatusMessage>
			return {
				...state, 
				connectionStatusMessage: typedAction.message
			}
		}

		case ActionType.logOut:
		case ActionType.connectionStateLoggedOut:
			return {
				...state,
				connectionState: ConnectionState.loggedOut,
				websocketUrl: '',
			}

		case ActionType.connectionStateConnecting:
			return {
				...state,
				connectionState: ConnectionState.connecting,
				websocketUrl: action.url,
			}

		case ActionType.connectionStateConnected:
			return {
				...state,
				connectionState: ConnectionState.connected,
			}

		case ActionType.connectionStateErrored:
			return {
				...state,
				connectionState: ConnectionState.errored,
			}
		
		case ActionType.serverEnqueueCommands:
			return {
				...state,
				serverCommandQueue: [...state.serverCommandQueue, ...action.commands]
			}
		
		case ActionType.serverDequeueCommands:
			return {
				...state,
				serverCommandQueue: state.serverCommandQueue.slice(action.count)
			}

		case ActionType.setWindowHash: {
			const typedAction = action as ActionObject<ActionType.setWindowHash>
			return {
				...state,
				page: typedAction.page,
			}
		}

		case ActionType.setFocusedCard: {
			const typedAction = action as ActionObject<ActionType.setFocusedCard>
			const page = (typedAction.datumId!=='') ? stringify({card: typedAction.datumId}) : ''
			return {
				...state,
				page: page,
			}
		}
		default:
			return state
	}
}
