import React, { Component } from 'react'
import './App.css'
import Cards from './components/cardcontainer'
import Login from './components/login'
import KeyCode from 'keycode-js'
import { Action, ServerAction, ActionObject } from './actions'
import { connect } from 'react-redux'
import Server from './backend/servercomponent'
import { State as GlobalState, ConnectionState } from './reducer'
import QueryString from 'query-string'
import FocusedCard from './components/focusedcard'
import { Version as ApiVersion, DatumObject } from 'datum-api'
import { version as ClientVersion } from '../package.json'
import { History, createBrowserHistory, Location } from 'history'

interface OwnProps {}
interface StateProps {
	page:string
	focusedDatumId?:string
	data:DatumObject[]
	connectionState:ConnectionState
	serverVersion:{server:string, api:string}
}
interface DispatchProps {
	onWindowHashChange:(params:string)=>void
	setServerVersion:(object:any)=>void
}
interface Props extends OwnProps, StateProps, DispatchProps {}

interface State {
}

const mapStateToProps = (state:GlobalState, props:OwnProps):StateProps=>{
	return {
		page: state.page,
		focusedDatumId: QueryString.parse(state.page)['card'] as string,
		data: state.data,
		connectionState: state.connectionState,
		serverVersion: state.serverVersion,
	}
}
const mapDispatchToProps = (dispatch:React.Dispatch<ActionObject>, props:OwnProps):DispatchProps=>{
	return {
		onWindowHashChange: (hash:string)=>dispatch(Action.setWindowHash(hash)),
		setServerVersion: (v:any)=>dispatch(ServerAction.serverVersion(v.server, v.api)),
	}
}

const requestServerVersion = async ()=>{
	const response = await fetch('/api/version')
	if (response.status === 200) {
		const version = await response.json()
		return version
	}
	else {
		throw new Error()
	}
}

const history:History = createBrowserHistory()

class App extends Component<Props, State> {

	state = {
	}

	unlisten:(()=>void)[] = []

	onKeyDown = (e:Event)=>{
		const event = e as unknown as React.KeyboardEvent<HTMLDocument>
		if (event.keyCode === KeyCode.KEY_ESCAPE) {
			//this.props.editCancel()
		}
	}

	locationChange = (location:Location)=>{
		this.props.onWindowHashChange(location.hash)
	}

	componentDidUpdate(prevProps: Readonly<Props>) {
		if(prevProps.page !== this.props.page) {
			history.push({hash:this.props.page})
		}
	}

	componentDidMount() {
		this.unlisten.push(history.listen(this.locationChange))
		this.locationChange(history.location)
		document.addEventListener('keydown', this.onKeyDown)
		this.unlisten.push(()=>document.removeEventListener('keydown', this.onKeyDown))
		requestServerVersion().then(this.props.setServerVersion).catch(()=>{})
	}

	componentWillUnmount() {
		this.unlisten.map(f=>f())
		this.unlisten = []
	}

	render() {
		const focused = this.props.focusedDatumId && (this.props.focusedDatumId !== '')
		const connectionState = this.props.connectionState
		return (
			<div className='application'>
				<div className='dev-version'>
					<div>c:{ClientVersion}[{ApiVersion}]</div>
					<div>s:{this.props.serverVersion.server}[{this.props.serverVersion.api}]</div>
				</div>
				<Server />
				{connectionState === ConnectionState.loggedOut && <Login />}
				{connectionState === ConnectionState.pending && <div>Pending</div>}
				{connectionState === ConnectionState.connecting && <div>Connecting</div>}
				{connectionState === ConnectionState.connected && <Cards />}
				{connectionState === ConnectionState.errored && <div>Errored</div>}
				{focused && <FocusedCard datumId={this.props.focusedDatumId} />}
			</div>
		)
	}
}

export default connect<StateProps, DispatchProps, OwnProps, GlobalState>(mapStateToProps, mapDispatchToProps)(App)
